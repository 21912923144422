import React, { useContext } from "react";
import { Layout } from "@layout";
import { Grid, Flex, Alert, Title, Text, Box } from "@components";
import { TableComisiones } from "@afiliado/Comisiones";
import AdminContext from "@context";

function Comisiones() {
	const { auth } = useContext(AdminContext);
	const { username } = auth;

	return (
		<Layout title={`Comisiones - ${username}`}>
			<Grid className="pv:2">
				<Title className="mb:05">Referidos de {username || "Afiliado"}</Title>

				<Flex className="mb:2">
					<Alert type="info" align="center" className="mb:1 mr:1">
						<Text fs={12} fw={300}>
							Hola! esta es tu lista de referidos y comisiones
						</Text>
					</Alert>
					<Alert type="warning" align="center" className="mr:1 mb:1">
						<Text fs={12} fw={300}>
							Se paciente! las comisiones pueden tardar un poco en verse
							reflejadas dependiendo del estado de las negociaciones
						</Text>
					</Alert>
				</Flex>

				<Box>
					<Text fs={18} fw={400} className="mb:05">
						Lista de Referidos
					</Text>
					<TableComisiones />

					<Flex justify="end" className='mt:2'>
						<Alert type="info" align="center" className="mb:1" reverse inline>
							<Text fs={12} fw={300} align="end">
								Completa tu información en la sección de facturación para reclamar tus
								comisiones.
							</Text>
						</Alert>
					</Flex>
				</Box>
			</Grid>
		</Layout>
	);
}

export default Comisiones;
