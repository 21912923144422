import React, { useEffect, useState, useContext } from "react";
import { Box, Flex, Col, Text, Button } from "@components";

import AdminContext from "@context";
import { negociaciones, porcentajes } from "@constants";
import { getRecomendados } from "@services/afiliados";
import { format } from "@utils";

function TableComisiones() {
	const {
		auth: { unique_code, token },
	} = useContext(AdminContext);
	const [recomendados, setRecomendados] = useState([]);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		getRecomendados(unique_code, token).then(({ data }) => {
			let t = 0;
			const newData = data.map((p) => {
				let negociacion = negociaciones.filter(
					(n) => n.label === p.negociacion
				)[0];

				if (!negociacion) {
					negociacion = negociaciones[negociaciones.length - 1];
				}

				let comision = porcentajes.filter((n) => +n.id === +p.comision)[0];

				console.log(comision);

				if (comision?.id === 9999) {
					comision.short = `${p.comisionOtra}%`;
				}

				if (!comision) {
					comision = {
						short: "0%",
					};
				}

				if (p.esCliente) {
					t += +p.cpv;
				}

				return {
					...p,
					negociacion,
					comision,
				};
			});

			setTotal(t);
			setRecomendados(newData);
		});
	}, []);

	return (
		<>
			{!!recomendados?.length &&
				recomendados.map(
					(
						{
							id,
							name,
							lname,
							country,
							request,
							recomended_by,
							comision,
							negociacion,
						},
						key
					) => (
						<Box inline className="ph:1 pv:05 mb:1" key={key}>
							<Flex>
								<Col autofit className="pr:1">
									<Text fw={500} fs={14}>
										{key + 1}
									</Text>
									<Text fs={10} opacity={0.4}>
										ID
									</Text>
								</Col>
								<Col xs={2}>
									<Text fw={500} fs={14}>
										{name || <span className="opacity:5">NA</span>}
									</Text>
									<Text fs={10} opacity={0.4}>
										Nombres
									</Text>
								</Col>
								<Col xs={2}>
									<Text fw={500} fs={14}>
										{lname || <span className="opacity-5">NA</span>}
									</Text>
									<Text fs={10} opacity={0.4}>
										Apellidos
									</Text>
								</Col>
								<Col xs={3}>
									<Text fw={500} fs={14}>
										{country || "México"}
									</Text>
									<Text fs={10} opacity={0.4}>
										Región
									</Text>
								</Col>
								<Col xs={3}>
									<Text fw={500} fs={14}>
										{recomended_by}
									</Text>
									<Text fs={10} opacity={0.4}>
										Código de afiliación
									</Text>
								</Col>
								<Col xs={4}>
									<Text fw={500} fs={14}>
										{request || "NA"}
									</Text>
									<Text fs={10} opacity={0.4}>
										Servicio consultado
									</Text>
								</Col>
								<Col xs={3}>
									<Text fw={500} fs={14}>
										<span className={negociacion?.style}>
											{negociacion?.label}
										</span>
									</Text>
									<Text fs={10} opacity={0.4}>
										Estado de negociación
									</Text>
								</Col>
								<Col autofit>
									<Text fw={500} fs={14}>
										{comision.short || "0%"}
									</Text>
									<Text fs={10} opacity={0.4}>
										% de comision
									</Text>
								</Col>
							</Flex>
						</Box>
					)
				)}

			{!!!recomendados?.length ? (
				<>
					<Text fs={16} opacity={0.5}>
						No tienes comisiones registradas
					</Text>
					<Text className="mt:2" fs={16} opacity={0.5}>
						Empieza a compartir tu link único de afiliación para generar comsisiones, ver sus estatus y muchos más en esta sección.
					</Text>
					<Flex>
						<Col autofit>
							<Box inline className="mt:2 p:1">
								<Text fs={14}>
									<span>https://digitalbooting.com</span>/<b>a</b>/
									<b className="color:link">{unique_code}</b>
								</Text>
							</Box>
						</Col>
					</Flex>
				</>
			) : (
				<>
					<Flex justify="end" className="mt:4">
						<Col autofit>
							<Text align="right" fw={500} fs={14}>
								Total de comisiones
							</Text>
							<Text align="right" fw={500} fs={10} opacity={0.4}>
								Comisiones sin reclamar
							</Text>
						</Col>
						<Col autofit className="pl:2">
							<Text align="right" fw={500} fs={14}>
								{total ? format(total) : "0.00"}
							</Text>
							<Text align="right" fw={500} fs={10} opacity={0.4}>
								Moneda MXN
							</Text>
						</Col>
					</Flex>

					<Flex justify="end" className="mt:2">
						<Col autofit>
							<Button
								square
								primary
								disabled
								title="Completa tu información de facturación"
							>
								Reclamar comisiones
							</Button>
						</Col>
					</Flex>
				</>
			)}
		</>
	);
}

export default TableComisiones;
